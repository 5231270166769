export default {
    handleErrosTransferencia(errorData, errorForm, numberOfItens) {
        let error = errorData.errors;

        errorForm.campanha = error.id_campanha ? error.id_campanha[0] : '';
        errorForm.numero_nota_fiscal = error.numero_nota_fiscal ? error.numero_nota_fiscal[0] : '';
        errorForm.id_unidade_origem = error.id_unidade_origem ? error.id_unidade_origem[0] : '';
        errorForm.lotes = error.movimentacao_item ? error.movimentacao_item[0] : '';
        errorForm.error = '';

        if(!errorForm.lotes) {
            for (let index = 0; index < numberOfItens; index++) {
                errorForm.id_lote[index] = error['movimentacao_item.' + index + '.id_lote'] ? 'Item ' +(index+1) + ': ' + error['movimentacao_item.' + index +'.id_lote'][0] : null;
                errorForm.quantidade[index] = error['movimentacao_item.' + index + '.quantidade'] ? 'Item ' +(index+1) + ': ' + error['movimentacao_item.' + index +'.quantidade'][0] : null;
            }
        }

        if(typeof error == 'string') {
            errorForm.error = error;
        }
    }
}