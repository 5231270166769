<template>
    <div class="content-wrapper">
        <ConfirmModal 
            itemIdentifier="usuário"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="redirectToList"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="redirectToList"
        />

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirectToList"
        />

        <AddItemModal
          :showModal="modalItem.showModal"
          :vacinaSelect="vacinaSelect"
          :fabricanteSelect="fabricanteSelect"
          :frascoSelect="frascoSelect"
          :itemEdit="modalItem.item"
          :editModal="modalItem.editModal"
          @confirmed="addItemToList"
          @canceled="closeModalAddItem"
          :idUnidade="idUnidadeOrigemSelecionada"
        />

        <PageHeader screenName="Editar transferência" :linkItems="linkItems"/>
        <main v-if="loadingInfo" class="card">
        <div  class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
        </div>
        </main>
        <main v-else class="card">
            <h2 class="pt-1 px-2">Transferência #{{form.codigo}}</h2>
                <b-alert class="p-1" variant="danger" :show="form.errorApi.error ? true : false">
                    {{form.errorApi.error}}
                </b-alert>
            <validation-observer ref="transferenciaRules">
                <b-form>
                    <b-row class="pt-1 px-2">
                       <b-col lg="3" md="6" sm="12">
                            <b-form-group 
                            label="Nota fiscal de transferência*"
                            label-for="nota-fiscal-input"
                            label-class="font_size_label_estoque"
                            >
                            <validation-provider
                                #default="{ errors }"
                                name="numero_nota_fiscal"
                                rules="required"
                                >
                                <b-form-input
                                    id="nota-fiscal-input"
                                    v-model="form.numero_nota_fiscal"
                                    v-mask="('N'.repeat(15))"
                                    @input="() =>{
                                    form.numero_nota_fiscal = form.numero_nota_fiscal.toUpperCase()
                                    }"
                                    placeholder="Digite o número da nota fiscal"
                                />
                                <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="form.errorApi.numero_nota_fiscal" class="text-danger">
                                        {{ form.errorApi.numero_nota_fiscal }}
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                       <b-col lg="3" md="6" sm="12">
                            <b-form-group 
                            label="Unidade Operacional de Origem*"
                            label-for="origem-input"
                            label-class="font_size_label_estoque"
                            >
                            <validation-provider
                                #default="{ errors }"
                                name="id_unidade_origem"
                                rules="required"
                                >
                                <v-select 
                                id="origem-input"
                                v-model="form.origem"
                                :options="unidadeSelect"
                                label="descricao_unidade"
                                placeholder="Selecione uma unidade"
                                :clearable="true"
                                :disabled="disabled.origem"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="form.errorApi.id_unidade_origem" class="text-danger">
                                        {{ form.errorApi.id_unidade_origem }}
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                       <b-col lg="3" md="6" sm="12">
                            <b-form-group 
                            label="Unidade Operacional de Destino*"
                            label-for="destino-input"
                            label-class="font_size_label_estoque"
                            >
                            <validation-provider
                                #default="{ errors }"
                                name="id_unidade_destino"
                                rules="required"
                                >
                                <v-select 
                                id="destino-input"
                                v-model="form.destino"
                                :options="unidadeSelect"
                                label="descricao_unidade"
                                placeholder="Selecione uma unidade"
                                :clearable="true"
                                :disabled="disabled.destino"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="form.errorApi.id_unidade_destino" class="text-danger">
                                        {{ form.errorApi.id_unidade_destino }}
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                    </b-row>
                       <b-row class="px-2">
                        <b-col lg="8" md="8" sm="12">
                            <b-form-group 
                            label="Observações"
                            label-class="font_size_label_estoque"
                            >
                            <validation-provider
                                #default="{ errors }"
                                name="observacao"
                                >
                                    <b-form-textarea
                                        id="observacao-input"
                                        v-model="form.observacao"
                                        rows="3"
                                        :state="form.observacao.length <= 500 || form.observacao.length == 0 ? null : false"
                                        placeholder="Observações..."
                                        no-resize
                                    />
                                    <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="form.errorApi.observacao" class="text-danger">
                                        {{ form.errorApi.observacao }}
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                       </b-row>
                </b-form>
            </validation-observer>
            <div>
                 <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                </div>
                <section class="list-items-transferencia" v-if="!loadingData">
                    <b-table
                        id="table-items-transferencia"
                        responsive
                        :stickyColumn="true"
                        :busy.sync="filtering"
                        :fields="table.fields"
                        :items="table.items"
                        no-local-sorting
                        :no-sort-reset="true"
                    >
                        <template #cell(vacina)="row">
                            <span class=" comprimento_maximo_celulas font-weight-bold"
                            v-b-tooltip.hover.righttop="{ variant: 'light' }"
                            :title="row.item.vacina.nome_tecnico"
                            >
                                {{ row.item.vacina.nome_tecnico }}
                            </span>
                        </template>
                        <template #cell(fabricante)="row">
                            <span class="font-weight-bold">
                                {{ row.item.fabricante.descricao }}
                            </span>
                        </template>
                        <template #cell(lote)="row">
                            <span class="font-weight-bold">
                                {{ row.item.lote }}
                            </span>
                        </template>
                        <template #cell(frasco)="row">
                            <span class="font-weight-bold custom rounded-pill" :class="row.item.frasco.quantidade == 1 ? 'mono' : 'multi'">
                                {{ row.item.frasco.descricao }}
                            </span>
                        </template>
                        <template #cell(validade)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                {{ formatDate(row.item.validade, 'DD/MM/YYYY') }}
                            </span>
                        </template>
                        <template #cell(quantidade)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                {{ row.item.quantidade }}
                            </span>
                        </template>
                        <template #cell(valor_unitario)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                R${{ formatDotDecimal(row.item.valor_unitario) }}
                            </span>
                        </template>
                        <template #cell(valor_total)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                R${{ formatDotDecimal(row.item.valor_total) }}
                            </span>
                        </template>
                        <template #cell(actions)="row">
                            <feather-icon
                                id="entrada-show"
                                role="button"
                                class="text-custom-blue mr-1"
                                icon="EditIcon"
                                size="18"
                                @click.prevent="openModalAddItem(row.item, row.index)"
                            />
                            <feather-icon
                                id="entrada-show"
                                role="button"
                                class="text-custom-blue"
                                icon="Trash2Icon"
                                size="18"
                                @click.prevent="removeItemOfList(row.index)"
                            />
                        </template>
                    </b-table>
                    <div class="pb-2 px-1">
                    <span v-if="!table.items.length" class="ml-1">Nenhum item inserido</span>
                    <small v-if="form.errorApi.lotes" class="text-danger">
                        {{ form.errorApi.lotes }}
                    </small>
                    <small v-if="form.errorApi.quantidade.length" class="text-danger pl-1 d-flex flex-column">
                        <span 
                        v-for="(errorValidate, index) in form.errorApi.quantidade"
                        :key="index"
                        >
                        {{ errorValidate }}
                        </span>
                    </small>
                    <small v-if="form.errorApi.id_lote.length" class="text-danger pl-1 d-flex flex-column">
                        <span 
                        v-for="(errorValidate, index) in form.errorApi.id_lote"
                        :key="index"
                        >
                        {{ errorValidate }}
                        </span>
                    </small>
                    </div>
                    <div class="pb-2 px-1">
                        <b-row class="p-2">
                            <b-col lg="4" md="6" sm="7">
                                <b-button @click.prevent="openModalItem" variant="outline-custom-blue">
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-50"  
                                />
                                <span class="align-middle">Novo Item</span>
                                </b-button>
                            </b-col>
                            <b-col lg="8" md="6" sm="7" class="border-top d-flex justify-content-end">
                                <div class="mr-2 text-center">
                                    <p class="mb-0 mt-1">
                                        Qt. Doses
                                    </p>
                                    <span class="font-weight-bold mb-0">
                                        {{ info.quantidade_total }}
                                    </span>
                                </div>
                                <div class="text-center">
                                    <p class="mb-0 mt-1">
                                        Valor Total R$
                                    </p>
                                    <span class="font-weight-bold mb-0">
                                        R${{ formatDotDecimal((info.valor_total).toFixed(2)) }}
                                    </span>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </section>
                <section class="m-2">
                <b-button id="save-entrada" 
                    class="mr-2 mb-2-sm" 
                    variant="custom-blue"
                    @click.prevent="validationForm"
                    :disabled="saving"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar alterações</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-entrada" 
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="openModalConfirm"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Descartar</span>
                </b-button>
            </section>
                
            </div>
        </main>
    </div>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard, BFormTextarea,
BFormDatepicker, BInputGroup, BInputGroupAppend, BAlert } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import moment from 'moment';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import InfograficoEstoqueCard from '@/views/components/custom/cards/InfograficoEstoqueCard.vue';
import InfoCard from '@/views/components/custom/cards/InfoCard.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import AddItemModal from '@/views/components/custom/modals/estoque/Add-novo-item-transferencia.vue';
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import handleErrorsService from './Services/HandleErrors'


export default {
    title: 'Editar transferência',
    
    components: {
        BForm, BCol, BRow, BFormGroup, BFormInput,
        BButton, BTable, TableSwitch, BCard,
        vSelect, CustomPagination, PageHeader,
        TimedAlert, VuePerfectScrollbar, BSpinner,
         BFormDatepicker, BInputGroup, BInputGroupAppend,
         InfograficoEstoqueCard, InfoCard, BFormTextarea,
         AddItemModal, ConfirmModal, SucessoModal, ErrorModal,
         ValidationProvider, ValidationObserver, BAlert
    },

    directives: {
        'b-tooltip': VBTooltip
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Estoque',
                    active: true
                },
                {
                    name: 'Transferência',
                    active: true
                },
                {
                    name: 'Editar transferência',
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edicao'
            },
            modalItem: {
                showModal: false,
                item: null,
                editModal: false,
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            alert: {
                errorMessage: '',
                show: false
            },
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            filtering: false,
            loadingData: false,
            without: false,
            searchTerm: '',
            table: {
                fields: [
                    {key: "vacina", label: "VACINA", sortable: false, class: 'mw-100'},
                    {key: "fabricante", label: "FABRICANTE", sortable: false, class: 'mw-100'},
                    {key: "lote", label: "LOTE", sortable: false, class: 'mw-100'},
                    {key: "frasco", label: "FRASCO", sortable: false, class: 'mw-150'},
                    {key: "validade", label: "VALIDADE", sortable: false, class: 'mw-100'},
                    {key: "quantidade", label: "QT", sortable: false, class: 'mw-100'},
                    {key: "valor_unitario", label: "VALOR UN.", sortable: false, class: 'mw-100'},
                    {key: "valor_total", label: "VALOR TOTAL", sortable: false, class: 'mw-100'},
                    {key: "actions", label: "AÇÕES", sortable: false, class: 'mw-150 text-center col-1'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'lote',
                order: 'desc'
            },
            paginationData: {
                currentPage: 0,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
            },
            form:{
                codigo: '',
                numero_nota_fiscal: '',
                origem: '',
                destino: '',
                observacao: '',
                campanha: '',
                errorApi: {
                    campanha: '',
                    numero_nota_fiscal: '',
                    id_unidade_origem: '',
                    id_unidade_destino: '',
                    observacao: '',
                    lotes: '',
                    id_lote: '',
                    quantidade: '',
                    error: '',
                }
            },
            idUnidadeOrigemSelecionada: null,
            info: {
                valor_total: 0,
                quantidade_total: 0,
            },
            disabled:{
                origem: true,
                destino: true,
            },
            unidadeSelect: [],
            vacinaSelect: [],
            fabricanteSelect: [],
            frascoSelect: [],
            campanhaSelect: [],
            saving: false,
            data: {},
            loadingInfo: true
        }
    },
    mounted() {
        if(typeof this.$route.params.idTransferencia == "undefined") {
        this.openModalError();
        return;
        }
        this.$http.get(this.$api.movimentacaoParameter(this.$route.params.idTransferencia)).then(({ data }) => {
            this.preenchimentoFormulario(data);
            this.linkItems[2].name = 'Transferência ' + data.codigo;
            this.loadingInfo = true;
            this.loadAllInfo();
        })
    },

    methods: {
        preenchimentoFormulario(dados){
            this.form.codigo = dados.codigo;
            this.form.numero_nota_fiscal = dados.numero_nota_fiscal;
            this.form.origem = dados.itens[0].lote_unidade_origem.unidade.descricao;
            this.form.destino = dados.itens[0].lote_unidade_destino.unidade.descricao;
            this.form.observacao = dados.observacao ? dados.observacao : '';
            let itens = [];

            this.idUnidadeOrigemSelecionada = dados.itens[0].lote_unidade_origem.unidade.id_unidade;

            dados.itens.map((item)=>{
                  const valor_unitario = item.lote_unidade_origem.nota_fiscal_itens[0].valor_unitario_conversao;
                  itens.push({
                      id_lote: item.lote_unidade_origem.lote.id_lote,
                      vacina: item.lote_unidade_origem.lote.vacina,
                      lote: item.lote_unidade_origem.lote.numero_lote,
                      validade: item.lote_unidade_origem.lote.validade,
                      fabricante: item.lote_unidade_origem.lote.fabricante,
                      frasco: item.lote_unidade_origem.lote.frasco,
                      quantidade: item.quantidade,
                      valor_unitario: valor_unitario,
                      valor_total: (item.quantidade * valor_unitario).toFixed(2),
                      edit: true
                  });
              });

            this.table.items = itens;
            this.updateInfoValues();
        },
        validationForm() {
            this.$refs.transferenciaRules.validate().then(success => {
                if (success) {
                    this.saving = true;
                    this.saveInfo();
                }
            })
        },
        saveInfo(){
            const parameters = this.prepareDataToSave();

            this.$http.put(this.$api.movimentacaoParameter(this.$route.params.idTransferencia), parameters)
            .then(() => {
                this.openModalSucesso();

                this.cleanForm();
                this.saving = false;
            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosTransferencia(data, this.form.errorApi, this.table.items.length);
                this.saving = false;
            })
        },
        prepareDataToSave(){
            let lotes = [];

            this.table.items.map(lote => {
                lotes.push({
                    id_lote: lote.id_lote,
                    quantidade: lote.quantidade
                });
            })

            return {
                numero_nota_fiscal: this.form.numero_nota_fiscal,
                observacao: this.form.observacao,
                movimentacao_item: lotes
            };
        },
        async loadAllInfo() {
            const parameters = {
                ativo: true
            }
            await this.$http.get(this.$api.campanha(), { params: parameters }).then(({ data }) => {
                this.campanhaSelect = data;
            });

            await this.$http.get(this.$api.unidade(), { params: parameters }).then(({ data }) => {
                this.unidadeSelect = data;
            });

            await this.$http.get(this.$api.fabricante(), { params: parameters }).then(({ data }) => {
                this.fabricanteSelect = data;
            });

            await this.$http.get(this.$api.frasco(), { params: parameters }).then(({ data }) => {
                this.frascoSelect = data;
            });
            this.loadingInfo = false;
        },
        searchVacinas(){
            const parameters = {
                ativo: true
            }
            this.$http.get(this.$api.saveAndGetVacina(), { params: parameters }).then(({ data }) => {
                this.vacinaSelect = data.data;
            });
        },
        addItemToList(item) {
            if(this.table.items[item.index]){
                this.table.items[item.index].vacina = item.vacina;
                this.table.items[item.index].frasco = item.frasco;
                this.table.items[item.index].fabricante = item.fabricante;
                this.table.items[item.index].id_lote = item.id_lote;
                this.table.items[item.index].numero_lote = item.lote;
                this.table.items[item.index].quantidade = item.quantidade;
                this.table.items[item.index].validade = item.validade;
                this.table.items[item.index].valor_total = item.valor_total;
                this.table.items[item.index].valor_unitario = item.valor_unitario;
            }else{
                this.table.items.push(item);
            }
            this.updateInfoValues();
            this.modalItem.showModal = false;
            this.modalItem.item = null;
        },
        updateInfoValues(){
            let valorTotal = 0, quantidadeTotal = 0;
            this.table.items.map(lote => {
                valorTotal += parseFloat(lote.valor_total);
                quantidadeTotal += parseFloat(lote.quantidade);
            })

            this.info.valor_total = valorTotal;
            this.info.quantidade_total = quantidadeTotal;
        },
        removeItemOfList(index){
            this.loadingData = true;
            this.table.items.splice(index, 1);
            setTimeout(() => this.loadingData = false, 500)
        },
        formatString(string){
            const formatted = string.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z])/g, '');
            return formatted.toLowerCase();
        },
        formatDate(string, format){
            return moment(string).format(format);
        },
       openModalError() {
            this.modalError.showModal = true;
        },
        redirectToList() {
           this.$router.push({name: 'transferencia-list'});
        },
       openModalAddItem(item, index) {
        if(item){
            item.index = index;
            this.modalItem.item = item;
            if(item.edit){
                this.modalItem.editModal = true;
            }
        }
        this.modalItem.showModal = true;
       },
       openModalItem() {
        this.modalItem.showModal = true;
       },
       openModalSucesso() {
            this.modalSucesso.showModal = true;
        },
       closeModalAddItem(){
        this.modalItem.showModal = false;
        this.modalItem.item = null;
        this.modalItem.editModal = false;
       },
       openModalConfirm() {
            this.modal.showModal = true;
        },
       closeModalConfirm() {
            this.modal.showModal = false;
        },
        formatDotDecimal(value){
            let string = value.toString();
            return string.replace('.', ',')
        }
    }
    
}
</script>

<style>
.font_size_label_estoque{
    font-size: 15px;
    margin-top: -5px;
}
.custom{
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
.mono{
    background-color: #f3eeff;
    color: #8c65e2;
}
.multi{
    background-color: #e3f6f4;
    color: #21a696;
}
.altura_max_scrollbar{
    max-height:100px;
    padding-right: 15px;
}
.comprimento_maximo_celulas{
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
</style>