<template>
  <b-modal
        id="add-transferencia"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        hide-footer
        centered
        v-model="showModal"
    >
        <div class="d-flex flex-column my-2">
            <h2>Novo Item</h2>
            <b-alert class="p-1" variant="danger" :show="withoutLote">
                Lote não existente no estoque.
            </b-alert>
            <validation-observer ref="itemRules">
                <b-form>
                    <b-row class="pt-1">
                       <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                            label="Lote*"
                            label-for="lote-input">
                            <validation-provider
                                #default="{ errors }"
                                name="lote"
                                rules="required"
                                >
                                <b-form-input
                                    id="lote-input"
                                    v-model="item.lote"
                                    placeholder="N° de Lote"
                                    maxLength="15"
                                    @input="searchLote"
                                    :disabled="disabled.lote"
                                />
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                       <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                            label="Quantidade*"
                            label-for="quantidade-input">
                             <validation-provider
                                #default="{ errors }"
                                name="quantidade"
                                rules="required"
                                >
                                <b-form-input
                                    id="quantidade-input"
                                    v-model="item.quantidade"
                                    placeholder="N° de doses"
                                    @keydown="item.quantidade.length == 0 && $event.key === '0' && $event.preventDefault()"
                                    v-mask="'#######'"
                                />
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                    </b-row>
                    <b-row>
                       <b-col lg="12" md="12" sm="12">
                            <b-form-group 
                            label="Vacina*"
                            label-for="vacina-input">
                            <validation-provider
                                #default="{ errors }"
                                name="vacina"
                                rules="required"
                                >
                                <v-select 
                                id="vacina-input"
                                v-model="item.vacina"
                                :options="vacinaSelect"
                                label="nome_tecnico"
                                placeholder="Selecione uma vacina"
                                :clearable="true"
                                :disabled="disabled.vacina"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                    </b-row>
                    <b-row>    
                       <b-col lg="12" md="12" sm="12">
                            <b-form-group 
                            label="Fabricante*"
                            label-for="nota-fiscal-input">
                            <validation-provider
                                #default="{ errors }"
                                name="fabricante"
                                rules="required"
                                >
                                <v-select 
                                id="fabricante-input"
                                v-model="item.fabricante"
                                :options="fabricanteSelect"
                                label="descricao"
                                placeholder="Selecione o fabricante"
                                :clearable="true"
                                :disabled="disabled.fabricante"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                      </b-row>
                       <b-row>  
                        <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                            label="Tipo de frasco*"
                            label-for="dose-input">
                            <validation-provider
                                #default="{ errors }"
                                name="dose"
                                rules="required"
                                >
                                <v-select 
                                id="dose-input"
                                v-model="item.frasco"
                                :options="frascoSelect"
                                label="descricao"
                                placeholder="Selecione o tipo"
                                :clearable="true"
                                :disabled="disabled.frasco"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                                label="Validade*"
                                label-for="validade-input"
                            >
                            <validation-provider
                                #default="{ errors }"
                                name="validade"
                                rules="required"
                                >
                                <b-input-group>
                                    <b-form-input
                                        class="form-control"
                                        id="validade-input"
                                        v-mask="'##/##/####'"
                                        v-model="input.validade"
                                        placeholder="00/00/0000"
                                        autocomplete="off"
                                        @input="setInput"
                                        :disabled="disabled.validade"
                                    />
                                    <b-input-group-append>
                                        <b-form-datepicker
                                            class="form-control d-flex align-items-center"
                                            locale="pt-BR"
                                            v-model="item.validade"
                                            button-only
                                            right
                                            :hide-header="true"
                                            label-help="Selecione a data de validade"
                                            button-variant="custom-blue"
                                            clearable
                                            @input="setPicker"
                                            :disabled="disabled.validade"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                      </b-row>
                </b-form>
            </validation-observer>
        </div>
        <section class="d-flex justify-content-center mb-1">
                <b-button id="save-entrada" 
                    class="mr-2 mb-2-sm" 
                    variant="custom-blue"
                    @click.prevent="confirmAction"
                    :disabled="saving"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Inserir Item</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-entrada" 
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="cancelAction"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
    </b-modal>
</template>

<script>
import { BModal, BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard,
BFormDatepicker, BInputGroup, BInputGroupAppend, BAlert } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select';
import moment from 'moment';

export default {
  components: {
        BModal, BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard,
BFormDatepicker, BInputGroup, BInputGroupAppend, vSelect,
ValidationProvider, ValidationObserver, BAlert
    },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    itemEdit: {
      default: null,
    },
    editModal: {
      default: false,
    },
    vacinaSelect: {
        type: Array,
        default() {
            return []
        }
    },
    fabricanteSelect: {
      type: Array,
        default() {
            return []
        }
    },
    frascoSelect: {
      type: Array,
      default() {
            return []
        }
    },
    idUnidade: {
      required: false
    }
  },
  data(){
    return {
        required,
        item: {
            index: null,
            id_lote: '',
            lote:'',
            quantidade: '',
            vacina: '',
            fabricante: '',
            frasco: '',
            validade: '',
            valor_unitario: '',
        },
        input: {
            validade: ''
        },
        disabled: {
            lote: false,
            vacina: true,
            validade: true,
            frasco: true,
            fabricante: true,
        },
        withoutLote: false,
        saving: false,
    }
  },
  methods: {
    confirmAction() {
        this.$refs.itemRules.validate().then(success => {
            if (success) {
                let formattedItem = {
                    "index": this.item.index,
                    "id_lote": this.item.id_lote,
                    "vacina": this.item.vacina,
                    "fabricante": this.item.fabricante,
                    "lote": this.item.lote,
                    "frasco": this.item.frasco,
                    "validade": this.item.validade,
                    "quantidade": this.item.quantidade,
                    "valor_unitario": this.item.valor_unitario,
                    "valor_total": (this.item.valor_unitario * this.item.quantidade).toFixed(2),
                }

                this.$emit('confirmed', formattedItem);
                this.emptyForm();
                this.$refs.itemRules.reset();
            }
        })
    },
    cancelAction(modalEvent) {
        modalEvent.preventDefault();
        this.$emit('canceled', modalEvent);
        this.emptyForm();
    },
    setPicker() {
        this.input.validade = moment(this.item.validade).format('DD/MM/YYYY');
    },
    setInput() {
        this.item.validade = moment(this.input.validade, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
    fillForm(itemEdit) {
        this.item.index = itemEdit.index;
        this.item.doenca = itemEdit.doenca;
        this.item.vacina = itemEdit.vacina;
        this.item.fabricante = itemEdit.fabricante;
        this.item.frasco = itemEdit.frasco;
        this.item.lote = itemEdit.lote;
        this.item.validade = itemEdit.validade;
        this.item.quantidade = itemEdit.quantidade;
        this.setPicker();
    },
    emptyForm() {
        this.item.index = '';
        this.item.doenca = '';
        this.item.vacina = '';
        this.item.fabricante = '';
        this.item.frasco = '';
        this.item.lote = '';
        this.item.validade = '';
        this.input.validade = '';
        this.item.quantidade = '';
    },
    searchLote(){
        this.item.lote = this.item.lote.toUpperCase()

        if(this.item.lote.length == 0){
            this.withoutLote = false;
            return;
        }

        const params = {
            lote: this.item.lote,
        };

        if (this.idUnidade) {
          params['id_unidade'] = this.idUnidade;
        }

        this.$http.get(this.$api.lote(), {params})
        .then(({ data }) => {
            if(data.data.length > 0) {
                this.withoutLote = false;
                const lote = data.data[0]
                
                this.item.id_lote = lote.id_lote;
                this.item.vacina = {
                    id_vacina: lote.id_vacina,
                    nome_tecnico: lote.nome_tecnico_vacina,
                }
                this.item.fabricante = {
                    id_fabricante: lote.id_fabricante,
                    descricao: lote.descricao_fabricante,
                }
                this.item.frasco = {
                    id_frasco: lote.id_frasco,
                    descricao: lote.descricao_frasco,
                    quantidade: lote.quantidade_frasco,
                }
                this.item.validade = lote.validade;
                this.item.valor_unitario = lote.nota_fiscal_itens[0].valor_unitario;

                this.setPicker();
                this.disabled.fabricante = true;
                this.disabled.frasco = true;
                this.disabled.validade = true;
            }else{
                this.withoutLote = true;
                this.item.fabricante = '';
                this.item.vacina = '';
                this.item.frasco = '';
                this.item.validade = '';
                this.$refs.itemRules.reset();
                this.setPicker();

            }
        });
    }
  },
  watch: {
    itemEdit(val) {
        if(val && val.index != null) {
            this.fillForm(val);
        }
    },
    editModal(val) {
        if(val){
            this.disabled.lote = true;
        }else{
            this.disabled.lote = false;
        }
    }
  }

}
</script>

<style scoped>
    .bloco{
      height: 200px;
    }
    .list-enter-active,
    .list-leave-active {
      transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    .panel-enter-active,
    .panel-leave-active {
      transition: all 0.5s ease;
    }
    .panel-enter-from,
    .panel-leave-to {
      opacity: 0;
      height: 0;
      transform: translateX(30px);
    }
</style>
